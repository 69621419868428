/* eslint-disable prettier/prettier */
/* eslint-disable react/prop-types */
import React, {useState} from 'react';
import Layout from '../components/layout';
import Navbar from '../components/navbar/navbar';
import MobileNavbar from '../components/mobile-navbar/mobile-navbar';
import HeaderImage from '../assets/images/science-of-happiness/header.jpg';

import {
  HeroImage,
  LayoutUI,
  Header,
  Button,
  Stack,
  TextStyle,
  Checkbox,
  TextField
} from '../ui-kit';

import './signup.scss';

const Index = () => {
    const ZAPIER_URL = 'https://hooks.zapier.com/hooks/catch/7208022/o52ul9g/';
    const [daily, setDaily] = useState(false);
    const [weekly, setWeekly] = useState(false);
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [honeyPot, setHoneyPot] = useState('');

    const submitForm = event => {
        event.preventDefault();
        const data = new FormData(event.target);
        if (honeyPot.length === 0) {
          fetch(ZAPIER_URL, {
            method: 'POST',
            body: data
          })
            .then(() => {
              setSuccessMessage(
                'Welcome! So happy to have you part of this community. Your vitamin for the soul is on it’s way! 😊'
              );
            })
            .catch(() => {
              setSuccessMessage(
                "Whoops! Something went wrong and we didn't catch that. 🙃 Refresh the page and try again. If it continues to not work, reach out at hello@projecthappiness.org. We'll get is squared away!"
              );
            });
        }
      };

  return (
    <Layout>
      <Navbar />
      <MobileNavbar />
      <main className="page-signup">
        <HeroImage height="small" image={HeaderImage} />
        <LayoutUI narrow>
          <TextStyle center>
            <Stack alignment="center" vertical>
              <Header>Sign up for your vitamin for the soul :)</Header>
            </Stack>
          </TextStyle>
        </LayoutUI>
        <LayoutUI narrow className="page-signup__container">
          <TextStyle color="red">
            {!successMessage ? (
              <form
                target="_blank"
                className="page-signup__form"
                id="signup-form"
                onSubmit={e => submitForm(e)}
              >
                <div className="CheckboxContainer">
                  <Checkbox
                    checked={weekly}
                    color="red"
                    inactiveState="red-outline"
                    label="Weekly Re-Cap"
                    name="weekly"
                    className="recap"
                    onChange={() => setWeekly(!weekly)}
                  />
                  <Checkbox
                    checked={daily}
                    color="red"
                    inactiveState="red-outline"
                    label="Daily Inspiration"
                    name="daily"
                    className="inspiration"
                    onChange={() => setDaily(!daily)}
                  />
                  <Checkbox
                    checked={honeyPot}
                    color="red"
                    inactiveState="red-outline"
                    label="Honey Pot"
                    name="honeyPot"
                    className="ohnohoney"
                    onChange={() => setHoneyPot(!honeyPot)}
                  />
                </div>
                <div className="input__container">
                  <TextField
                    label="First Name"
                    color="red"
                    onChange={e => setFirstName(e.target.value)}
                    name="firstName"
                    value={firstName}
                  />
                  <TextField
                    label="Last Name"
                    color="red"
                    onChange={e => setLastName(e.target.value)}
                    name="lastName"
                    value={lastName}
                  />
                </div>
                <div className="input__container">
                  <TextField
                    label="email"
                    color="red"
                    onChange={e => setEmail(e.target.value)}
                    name="email"
                    value={email}
                  />
                  <Button primary color="red" type="submit">
                    Submit
                  </Button>
                </div>
              </form>
                ) : (
                  <p>{successMessage}</p>
                )}
          </TextStyle>
        </LayoutUI>
      </main>
    </Layout>
  );
};

Index.propTypes = {
  
};

export default Index;